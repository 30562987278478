import 'twin.macro';
import PageLayoutDefault from '@leuven2030/food/Page/PageLayoutDefault';
import { StyleContent } from '@leuven2030/food/styles/general';
import PageFooter from '@leuven2030/food/Page/PageFooter';

const Page404 = () => {
  return (
    <PageLayoutDefault>
      <div tw="bg-primary-400 py-12">
        <div css={StyleContent}>
          <div tw="flex flex-col space-y-6 sm:(flex-row items-start justify-between space-x-3 space-y-0) w-full">
            <div tw="space-y-4">
              <h1 tw="text-white font-black text-3xl sm:text-4xl">
                404 - Not Found
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div tw="py-24">
        <img src="assets/404.svg" tw="w-full" />
      </div>
    </PageLayoutDefault>
  );
};

export default Page404;
